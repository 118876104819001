<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="4">
        <v-menu
          ref="menu"
          v-model="fromDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fromDate"
              label="From Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              hint="From Date "
            ></v-text-field>
          </template>
          <v-date-picker
            ref="picker"
            v-model="fromDate"
            name="fromDate"
            :max="new Date().toISOString().substr(0, 10)"
            min="1950-01-01"
            @change="fromDateSave"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4">
        <v-menu
          ref="menu"
          v-model="toDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="toDate"
              label="To Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              hint="To Date "
              :disabled="fromDate == null"
            ></v-text-field>
          </template>
          <v-date-picker
            ref="picker"
            v-model="toDate"
            name="toDate"
            :min="fromDate || ''"
            @change="toDateSave"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4">
        <v-btn
          class="mt-8"
          elevation="1"
          fab
          color="blue"
          small
          @click="filterByDate"
          :disabled="fromDate == null || toDate == null"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn rounded disabled absolute right>{{ currentCenter.name }}</v-btn>
      </v-col>
    </v-row>
    <!-- <v-row v-for="delinquent in delinquents" :key="delinquent.id" class="bg"> -->
    <v-row class="bg">
      <v-col class="col">
        <v-card color="#2e86c1" rounded="xl" class="mx-auto">
          <v-card-actions>
            <v-icon color="white" large>mdi-account-supervisor</v-icon>
            <v-card-title><h6>Currently</h6></v-card-title>
            <v-card-subtitle class="headline font-weight-bold">{{
              currentDelinquents != null ? currentDelinquents.total : 0
            }}</v-card-subtitle>
          </v-card-actions>
          <v-card-text
            >Male:
            {{ currentDelinquents != null ? currentDelinquents.male : 0 }}
            | Female:
            {{
              currentDelinquents != null ? currentDelinquents.female : 0
            }}</v-card-text
          >
        </v-card>
      </v-col>

      <v-col class="col">
        <v-card color="#aed6f1" rounded="xl" class="mx-auto">
          <v-card-actions>
            <v-icon color="white" large>mdi-run</v-icon>
            <v-card-title><h6>Escaped</h6></v-card-title>
            <v-card-subtitle class="headline font-weight-bold"
              >{{ escapedDelinquents.total }}
            </v-card-subtitle>
          </v-card-actions>
          <v-card-text
            >Male: {{ escapedDelinquents.male }} | Female:
            {{ escapedDelinquents.female }}</v-card-text
          >
        </v-card>
      </v-col>

      <v-col class="col">
        <v-card color="#2ecc71" rounded="xl" class="mx-auto">
          <v-card-actions>
            <v-icon color="white" large>mdi-account-supervisor</v-icon>
            <v-card-title><h6>Re-Integrated</h6></v-card-title>
            <v-card-subtitle class="headline font-weight-bold">
              {{ reIntegratedDelinquents.total }}
            </v-card-subtitle>
          </v-card-actions>
          <v-card-text
            >Male: {{ reIntegratedDelinquents.male }}| Female:
            {{ reIntegratedDelinquents.female }}
          </v-card-text>
        </v-card>
      </v-col>

      <v-col class="col">
        <v-card color="#f1c40f" rounded="xl" class="mx-auto">
          <v-card-actions>
            <v-icon color="white" large>mdi-send</v-icon>
            <v-card-title><h6>Transfered</h6></v-card-title>
            <v-card-subtitle class="headline font-weight-bold"
              >{{ transferedDelinquents.total }}
            </v-card-subtitle>
          </v-card-actions>
          <v-card-text
            >Male: {{ transferedDelinquents.male }} | Female:
            {{ transferedDelinquents.female }}</v-card-text
          >
        </v-card>
      </v-col>
      <v-col class="col show">
        <v-card color="#ff0000" rounded="xl" class="mx-auto">
          <v-card-actions>
            <v-icon color="white" large>mdi-transit-transfer</v-icon>
            <v-card-title><h6>Recidivism</h6></v-card-title>
            <v-card-subtitle class="headline font-weight-bold"
              >{{ recidivistNumber }}
            </v-card-subtitle>
          </v-card-actions>
          <v-card-text>Male: 0 | Female: 0</v-card-text>
        </v-card>
      </v-col>
      <v-col class="col">
        <v-card color="#5d6d7e" rounded="xl" class="mx-auto">
          <v-card-actions>
            <v-icon color="white" large>mdi-account-supervisor</v-icon>
            <v-card-title><h6>Total Delinquents</h6></v-card-title>
            <v-card-subtitle class="headline font-weight-bold"
              >{{ totalDelinquents.total }}
            </v-card-subtitle>
          </v-card-actions>
          <v-card-text
            >Male:
            {{ totalDelinquents.male }}
            | Female:
            {{ totalDelinquents.female }}</v-card-text
          >
        </v-card>
      </v-col>
      <div class="show">
        <!-- <DelinquentByTimeTC /> -->
      </div>

      <v-col cols="12" lg="6">
        <!-- <DecisionTakenChart /> -->
        <br />

        <!-- <RehabTransferChart /> -->
        <br />
      </v-col>

      <v-col cols="12" lg="6">
        <!-- <DelByDistrictOfOrgin /> -->
      </v-col>

      <!-- <v-col cols="12" lg="6">
        <GeneralScreeningChart />
        <br />
        <MedicalScreeningChart />
        <br />
        <DelNumberByAge />
      </v-col> -->

      <!-- <v-col cols="12" lg="6">
        <DecisionTakenChart />
        <br />

        <RehabTransferChart />
        <br />
        <DelByDistrictOfOrgin />
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
// import GeneralScreeningChart from '@/components/GeneralScreeningChart.vue'
// import DelNumberByAge from '@/components/BarChart'
// import DelByDistrictOfOrgin from '@/components/ByDistrictOfOrgin.vue'
// import MedicalScreeningChart from '@/components/MedicalScreeningChart'
// import DecisionTakenChart from '@/components/DecisionTakenChart.vue'
// import RehabTransferChart from '@/components/RehabTransferChart.vue'
// import DelinquentByTimeTC from '@/components/DeliquentByTimeTC.vue'
import { mapState } from 'vuex'
export default {
  name: 'DashboardDashboard',
  components: {
    // GeneralScreeningChart,
    // DelNumberByAge,
    // DelByDistrictOfOrgin,
    // MedicalScreeningChart,
    // DecisionTakenChart,
    // DelinquentByTimeTC
    // RehabTransferChart
  },
  computed: {
    initialDate () {
      return new Date().toISOString().substr(0, 10)
    },
    formattedFromDate () {
      return this.fromDate
        ? this.$moment(this.fromDate).format('DD-MMM-YYYY')
        : this.fromDate
          ? this.$moment(this.fromDate).format('DD-MMM-YYYY')
          : ''
    },
    formattedToDate () {
      return this.toDate
        ? this.$moment(this.toDate).format('DD-MMM-YYYY')
        : this.retrievedToDate
          ? this.$moment(this.retrievedToDate).format('DD-MMM-YYYY')
          : ''
    },
    ...mapState('store', [
      'snackbar',
      'color',
      'text',
      'timeout',
      'decisions',
      'currentUser',
      'search',
      'currentCenter',
      'storeProvinces',
      'storeProvincesError'
    ])
  },
  data () {
    return {
      message: '',
      delinquents: [],
      toDateDisabled: true,
      recidivistNumber: 0,
      transferedToRehab: [],
      arrivalDates: [],
      graduationDates: [],
      fromDate: null,
      fromDateMenu: false,
      toDate: null,
      toDateMenu: false,
      disabled: true,
      currentDelinquents: {
        male: 0,
        female: 0,
        total: 0
      },
      totalDelinquents: {
        male: 0,
        female: 0,
        total: 0
      },
      transferedDelinquents: {
        male: 0,
        female: 0,
        total: 0
      },
      reIntegratedDelinquents: {
        male: 0,
        female: 0,
        total: 0
      },
      escapedDelinquents: {
        male: 0,
        female: 0,
        total: 0
      }
    }
  },
  mounted () {
    if (this.fromDate == null || this.toDate == null) {
      this.initialData()
    } else {
      this.filterByDate()
    }
  },

  methods: {
    initialData () {
      this.$dashboardService
        // .getAllDelinquentCards()
        .getDelinquentNumberByGender(
          'ADMITTED,REHABILITATION,TRANSFER_INITIATED'
        )
        .then(data => {
          data.filter(item => {
            this.currentDelinquents = item.currently
            // this.escapedDelinquents = item.escaped
            // this.transferedDelinquents = item.transfered
            // this.reIntegratedDelinquents = item.reunified
          })
        })

      this.$dashboardService
        // .getAllDelinquentCards()
        .getDelinquentNumberByGender()
        .then(data => {
          data.filter(item => {
            this.totalDelinquents = item.currently
            // this.escapedDelinquents = item.escaped
            // this.transferedDelinquents = item.transfered
            // this.reIntegratedDelinquents = item.reunified
          })
        })
      this.$dashboardService
        // .getAllDelinquentCards()
        .getDelinquentNumberByGender(
          'ADMITTED,REHABILITATION,TRANSFER_INITIATED'
        )
        .then(data => {
          data.filter(item => {
            // this.currentDelinquents = item.currently
            this.escapedDelinquents = item.escaped
            // this.transferedDelinquents = item.transfered
            // this.reIntegratedDelinquents = item.reunified
          })
        })
      // this.$dashboardService
      //   .getDelinquentNumberByGender('ESCAPED')
      //   .then(data => {
      //     this.escapedDelinquents = data
      //   })
      // this.$dashboardService
      //   .getDelinquentNumberByGender('TRANSFERRED')
      //   .then(data => {
      //     this.transferedDelinquents = data
      //   })
      // this.$dashboardService
      //   .getDelinquentNumberByGender('RELEASED,REUNIFIED')
      //   .then(data => {
      //     this.reIntegratedDelinquents = data
      //   })
    },
    filterByDate () {
      const dateFromTo = this.formattedFromDate + ',' + this.formattedToDate
      this.$dashboardService
        .getDelinquentNumberByGender(dateFromTo)
        .then(data => {
          this.totalDelinquents = data
        })

      // this.$dashboardService
      //   .getDelinquentRecidivist(
      //     'ADMITTED,REHABILITATION,TRANSFER_INITIATED',
      //     this.formattedFromDate,
      //     this.formattedToDate
      //   )
      //   .then(data => {
      //     this.recidivistNumber = data
      //   })

      // this.$dashboardService
      //   .getDelinquentToRehab(
      //     "ADMITTED,REHABILITATION,TRANSFER_INITIATED",
      //     this.formattedFromDate,
      //     this.formattedToDate
      //   )
      //   .then(data => {
      //     this.transferedToRehab = data;
      //   });

      this.$dashboardService
        .getDelinquentNumberByGender('ESCAPED', null, dateFromTo)
        .then(data => {
          this.escapedDelinquents = data
        })
      this.$dashboardService
        .getDelinquentNumberByGender('TRANSFERRED', null, dateFromTo)
        .then(data => {
          this.transferedDelinquents = data
        })
      this.$dashboardService
        .getDelinquentNumberByGender('RELEASED,REUNIFIED', null, dateFromTo)
        .then(data => {
          this.reIntegratedDelinquents = data
        })
    },
    fromDateSave (date) {
      const date1 = this.$moment(date).format('DD-MMM-YYYY')
      this.$refs.menu.save(date)
      if (this.isGraduationDateShown) {
        this.graduationDates[0] = date1
      } else {
        this.arrivalDates[0] = date1
      }
      this.toDateDisabled = false
      this.toDate = null
    },
    toDateSave (date) {
      const date1 = this.$moment(date).format('DD-MMM-YYYY')
      // this.$refs.menu.save(date1)
      this.$refs.menu.save(date)
      if (this.isGraduationDateShown) {
        this.graduationDates[1] = date1
      } else {
        this.arrivalDates[1] = date1
      }
    },
    complete (index) {
      this.list[index] = !this.list[index]
    }
  }
}
</script>

<style scoped>
.show {
  display: none;
}
</style>
