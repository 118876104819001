<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="4">
        <v-menu
          ref="menu"
          v-model="fromDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fromDate"
              name="fromDate"
              label="From Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              hint="From Date "
            ></v-text-field>
          </template>
          <v-date-picker
            ref="picker"
            v-model="fromDate"
            :max="new Date().toISOString().substr(0, 10)"
            min="1950-01-01"
            @change="fromDateSave"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4">
        <v-menu
          ref="menu"
          v-model="toDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="toDate"
              name="toDate"
              label="To Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              hint="To Date "
              :disabled="fromDate == null"
            ></v-text-field>
          </template>
          <v-date-picker
            ref="picker"
            v-model="toDate"
            :min="fromDate || ''"
            @change="toDateSave"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4">
        <v-btn
          class="mt-8"
          elevation="1"
          fab
          small
          color="blue"
          @click="filterByDate()"
          :disabled="fromDate == null || toDate == null"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn rounded disabled absolute right>{{ currentCenter.name }}</v-btn>
      </v-col>
    </v-row>
    <v-row class="bg">
      <v-col class="col" cols="3">
        <v-card color="#2e86c1" rounded="xl" class="mx-auto">
          <v-card-actions>
            <v-icon color="white" large>mdi-account-supervisor</v-icon>
            <v-card-title><h6>CURRENTLY</h6></v-card-title><br /><br /><br />
            <v-card-subtitle class="headline font-weight-bold">{{
              currentDelinquents != null ? currentDelinquents.total : 0
            }}</v-card-subtitle>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col class="col" cols="3">
        <v-card color="#aed6f1" rounded="xl" class="mx-auto">
          <v-card-actions>
            <v-icon color="white" large>mdi-run</v-icon>
            <v-card-title><h6>ESCAPED</h6></v-card-title><br /><br /><br />
            <v-card-subtitle class="headline font-weight-bold">{{
              escapedDelinquents != null ? escapedDelinquents.total : 0
            }}</v-card-subtitle>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col class="col" cols="3">
        <v-card color="#2ecc71" rounded="xl" class="mx-auto">
          <v-card-actions>
            <v-icon color="white" large>mdi-account-supervisor</v-icon>
            <v-card-title> <h6>REUNIFIED/RE-INTEGRATED</h6></v-card-title
            ><br /><br /><br />
            <v-card-subtitle class="headline font-weight-bold">{{
              reIntegratedDelinquents != null
                ? reIntegratedDelinquents.total
                : 0
            }}</v-card-subtitle>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col class="col" cols="3">
        <v-card color="#5d6d7e" rounded="xl" class="mx-auto">
          <v-card-actions>
            <v-icon color="white" large>mdi-account-supervisor</v-icon>
            <v-card-title><h6>TOTAL DELINQUENTS</h6></v-card-title
            ><br /><br /><br />
            <v-card-subtitle class="headline font-weight-bold">{{
              currentDelinquents != null ? totalDelinquents.total : 0
            }}</v-card-subtitle>
          </v-card-actions>
        </v-card>
      </v-col>
      <!-- <DeliquentByTimeRC /> -->
      <!-- <v-col cols="12" lg="6">
        <DelByTrade />
        <br />
        <DelByIntake />
      </v-col> -->

      <!-- <v-col cols="12" lg="6">
        <pieChart />
        <br />
        <DelByDistrictOfOrgin />
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
// import Donut from '@/components/Donut.vue'
// import DelByTrade from '@/components/ByTrade.vue'
// import DelByDistrictOfOrgin from '@/components/ByDistrictOfOrgin.vue'
// import DelByIntake from '@/components/ByIntake.vue'
// import DeliquentByTimeRC from '@/components/DelinquentByTimeRC.vue'
import { mapState } from 'vuex'
export default {
  name: 'DashboardDashboard',
  components: {
    // pieChart: Donut,
    // DelByTrade,
    // DelByDistrictOfOrgin,
    // DelByIntake,
    // DeliquentByTimeRC
  },
  computed: {
    formattedFromDate () {
      return this.fromDate
        ? this.$moment(this.fromDate).format('DD-MMM-YYYY')
        : this.fromDate
          ? this.$moment(this.fromDate).format('DD-MMM-YYYY')
          : ''
    },
    formattedToDate () {
      return this.toDate
        ? this.$moment(this.toDate).format('DD-MMM-YYYY')
        : this.retrievedToDate
          ? this.$moment(this.retrievedToDate).format('DD-MMM-YYYY')
          : ''
    },
    ...mapState('store', [
      'snackbar',
      'color',
      'text',
      'timeout',
      'decisions',
      'currentUser',
      'search',
      'currentCenter',
      'storeProvinces',
      'storeProvincesError'
    ])
  },
  data () {
    return {
      message: '',
      delinquents: [],
      toDateDisabled: true,
      arrivalDates: [],
      graduationDates: [],
      fromDate: null,
      fromDateMenu: false,
      toDate: null,
      toDateMenu: false,
      disabled: true,
      currentDelinquents: {
        male: 0,
        female: 0,
        total: 0
      },
      totalDelinquents: {
        male: 0,
        female: 0,
        total: 0
      },
      transferedDelinquents: {
        male: 0,
        female: 0,
        total: 0
      },
      reIntegratedDelinquents: {
        male: 0,
        female: 0,
        total: 0
      },
      escapedDelinquents: {
        male: 0,
        female: 0,
        total: 0
      }
    }
  },
  mounted () {
    if (this.fromDate == null || this.toDate == null) {
      this.initialData()
    } else {
      this.filterByDate()
    }
  },

  methods: {
    initialData () {
      this.$dashboardService
        // .getAllDelinquentCards()
        .getDelinquentNumberByGender(
          'ADMITTED,REHABILITATION,TRANSFER_INITIATED'
        )
        .then(data => {
          this.currentDelinquents = data
        })
      this.$dashboardService
        // .getAllDelinquentCards()
        .getDelinquentNumberByGender()
        .then(data => {
          this.totalDelinquents = data
        })
      this.$dashboardService
        .getDelinquentNumberByGender('ESCAPED')
        .then(data => {
          this.escapedDelinquents = data
        })
      this.$dashboardService
        .getDelinquentNumberByGender('TRANSFERRED')
        .then(data => {
          this.transferedDelinquents = data
        })
      this.$dashboardService
        .getDelinquentNumberByGender('RELEASED,REUNIFIED')
        .then(data => {
          this.reIntegratedDelinquents = data
        })
    },
    filterByDate () {
      const dateFromTo = this.formattedFromDate + ',' + this.formattedToDate
      this.$dashboardService
        .getDelinquentNumberByGender(dateFromTo)
        .then(data => {
          this.totalDelinquents = data
        })
      this.$dashboardService
        .getDelinquentNumberByGender('ESCAPED', null, dateFromTo)
        .then(data => {
          this.escapedDelinquents = data
        })
      this.$dashboardService
        .getDelinquentNumberByGender('TRANSFERRED', null, dateFromTo)
        .then(data => {
          this.transferedDelinquents = data
        })
      this.$dashboardService
        .getDelinquentNumberByGender('RELEASED,REUNIFIED', null, dateFromTo)
        .then(data => {
          this.reIntegratedDelinquents = data
        })
    },
    fromDateSave (date) {
      const date1 = this.$moment(date).format('DD-MMM-YYYY')
      this.$refs.menu.save(date)
      if (this.isGraduationDateShown) {
        this.graduationDates[0] = date1
      } else {
        this.arrivalDates[0] = date1
      }
      this.toDateDisabled = false
    },
    toDateSave (date) {
      const date1 = this.$moment(date).format('DD-MMM-YYYY')
      // this.$refs.menu.save(date1)
      this.$refs.menu.save(date)
      if (this.isGraduationDateShown) {
        this.graduationDates[1] = date1
      } else {
        this.arrivalDates[1] = date1
      }
    },
    complete (index) {
      this.list[index] = !this.list[index]
    }
  }
}
</script>
