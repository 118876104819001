<template>
  <div v-if="currentCenter.type === 'TRANSIT'">
    <TransitDashboard />
  </div>
  <div v-else-if="currentCenter.type === 'REHABILITATION'">
    <RehabDashboard />
  </div>
  <div
    v-else-if="
      // && currentUser.roles === null
      currentCenter.type === 'NRS_HEADQUARTERS'
    "
  >
    <NrsDashboard />
  </div>
  <div v-else>
    <AdminDashboard />
  </div>
</template>

<script>
import TransitDashboard from '../dashboard/TransitDashboard.vue'
import RehabDashboard from '../dashboard/RehabDashboard.vue'
import NrsDashboard from '../dashboard/NRSDashboard.vue'
import AdminDashboard from '../dashboard/adminDashboard.vue'
import { mapState } from 'vuex'
export default {
  dashboard: '',

  components: {
    TransitDashboard,
    RehabDashboard,
    NrsDashboard,
    AdminDashboard
  },

  computed: {
    ...mapState('store', [
      'snackbar',
      'color',
      'text',
      'timeout',
      'decisions',
      'currentUser',
      'search',
      'currentCenter',
      'storeProvinces',
      'storeProvincesError'
    ])
  },
  methods: {}
}
</script>
